import { initializeApp } from "firebase/app"
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore"

initializeApp({
  apiKey: process.env.GATSBY_API_FIRESTORE_APIKEY,
  projectId: process.env.GATSBY_API_FIRESTORE_PROJECT,
})

const db = getFirestore()

export const initLogger = async (surveyName) => {
  try {
    const docRef = await addDoc(collection(db, "sessions"), {
      survey_name: surveyName,
      version: window.location.hash.replace("#", ""),
      language: document.documentElement.lang,
      url: window.location.host + window.location.pathname,
      created: serverTimestamp(),
    })
    const session = docRef.id
    const localTime = new Date()
    return { session, localTime }
  } catch (error) {
    console.log(error)
  }
}

export const logScroll = async (session, entry, step, startTime) => {
  try {
    const docRef = collection(db, "sessions", session, "events")
    await addDoc(docRef, {
      event_type: "scroll",
      location: "home",
      question_name: entry,
      step_counter: step,
    })
    await updateDoc(doc(db, "sessions", session), {
      duration_in_seconds: new Date() - startTime,
    })
    const newStep = step + 1
    return newStep
  } catch (error) {
    console.log(error)
  }
}

// await this.$log.event({
//   event_type: "submitted_survey",
//   target: "TGM",
// });

export const logComplete = async (session) => {
  try {
    const docRef = collection(db, "sessions", session, "events")
    await addDoc(docRef, {
      event_type: "submitted_survey",
      target: "TGM",
    })
  } catch (error) {
    console.log(error)
  }
}
