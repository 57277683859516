import React, { createContext, useContext, useState } from "react"

export const TheContext = createContext({
  session: undefined,
  setSession: () => {},
  startTime: undefined,
  setStartTime: () => {},
  step: undefined,
  setStep: () => {},
})

export const TheProvider = ({ children }) => {
  const [session, setSession] = useState(undefined)
  const [step, setStep] = useState(0)
  const [startTime, setStartTime] = useState(undefined)
  const [tgmSession, setTgmSession] = useState(undefined)

  return (
    <TheContext.Provider
      value={{
        session: session,
        setSession: setSession,
        startTime: startTime,
        setStartTime: setStartTime,
        step: step,
        setStep: setStep,
        tgmSession: tgmSession,
        setTgmSession: setTgmSession,
      }}
    >
      {children}
    </TheContext.Provider>
  )
}

export const useSessionContext = () => useContext(TheContext)
