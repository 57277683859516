const showdown = require("showdown")
const converter = new showdown.Converter({
  openLinksInNewWindow: true,
})

export const URLParser = (text) => {
  const html = converter.makeHtml(text)
  return html
}

export { TheContext, TheProvider, useSessionContext } from "./context.jsx"
export { initLogger, logComplete, logScroll } from "./logger"
